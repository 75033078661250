<template>
    <div class="uv-page">
        <v-container fluid class="py-0 d-flex justify-end align-center">
            <uv-autocomplete :readonly="!isAdmin" v-model="companyData.value" :items="companyData.items" item-text="name"
                item-value="id" label="Company"></uv-autocomplete>
        
            <template v-if="companyData.value">
                <v-tooltip top>
                    <template #activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                            <v-file-input v-show="!importButtonBusy" hide-details class="ml-2 import-report" hide-input dense
                                prepend-icon="mdi-file-import" @change="importReport" accept=".zip" v-model="importedReport" :disabled="importButtonBusy">
                            </v-file-input>
                            <v-progress-circular class="mt-2 mr-2" v-show="importButtonBusy" indeterminate size="24"
                                color="primary" width="2">
                            </v-progress-circular>
                        </div>
                    </template>
                    <span>Import report</span>
                </v-tooltip>
        
                <uv-button color="error" class="mr-2" @click="deleteAllReportLogs()" icon title="Delete all report logs"
                    size="md">
                    <v-icon>{{ icons.mdiFileDocumentRemove }}</v-icon>
                </uv-button>
        
                <v-btn class="elevation-2" color="success" @click="toggleEditReportForm(null)" tile size="md">
                    <v-icon color="white">{{ icons.mdiPlus }}</v-icon> Report
                </v-btn>
            </template>
        </v-container>

        <v-data-table
            hover
            raised
            :headers="reports.fields"
            :items="reports.items"
            :loading="reports.loading"
            :expanded.sync="reports.expanded"
            :single-expand="true"
            item-key="id"
            class="elevation-6"
            :hide-default-footer="true"
            :disable-pagination="true"
        >
            <template #item.actions="row"> 
                
                <uv-button v-if="row.item.files && row.item.files.length > 0"
                    :disabled="row.item.actions.generate_btn.busy && row.item.wsProc.connection != null"
                    color="info"
                    icon
                    :title="(row.item.actions.details_btn.pressed ? 'Hide' : 'Show') + ' files'"
                    @click="
                        toggleDetails(
                            row,
                            row.item.actions.details_btn
                        )
                    "
                >
                    <v-icon>{{ icons.mdiEye }}</v-icon>
                </uv-button>

                <uv-button
                    :disabled="row.item.actions.generate_btn.busy"
                    color="warning"
                    icon
                    @click="showUploadFilesForm(row)"
                    title="Upload files"
                    ><v-icon>{{ icons.mdiUpload }}</v-icon>
                </uv-button>

                <uv-button v-if="row.item.xbrl && hasTemplate(row)"
                    color="success"
                    icon
                    :disabled="!wsStatusConnected"
                    @click="generateReport(row)"
                    :loading="row.item.actions.generate_btn.busy"
                    title="Generate report"
                >
                    <v-icon>{{ icons.mdiCogPlay }}</v-icon>
                </uv-button>
                <uv-button v-if="row.item.actions.generate_btn.busy && row.item.wsProc.connection != null"
                    color="error"
                    icon
                    @click="stopReport(row)"
                    title="Stop report generation"
                >
                    <v-icon>{{ icons.mdiCogStop }}</v-icon>
                </uv-button>
                <uv-button
                    :disabled="row.item.actions.generate_btn.busy"
                    color="warning"
                    @click="toggleEditReportForm(row)"
                    icon
                    title="Edit report info"
                >
                    <v-icon>{{ icons.mdiPencil }}</v-icon>
                </uv-button>

                <uv-button v-if="row.item.xbrl || !row.item.from_pdf"
                    :disabled="row.item.actions.generate_btn.busy"
                    icon
                    color="primary"
                    :to="{name:'HTML Editor', params:{id: row.item.id}}"
                    title="Edit report HTML"
                >
                    <v-icon>mdi-language-html5</v-icon>
                </uv-button>

                <uv-button
                     icon
                     :disabled="row.item.actions.generate_btn.busy"
                     v-if="row.item.xbrl"
                     color="warning"
                     :to="{name:'Taxonomy Editor', query:{reportId: row.item.id}}"
                     title="Edit taxonomy"
                 >
                     <v-icon>mdi-table-edit</v-icon>
                </uv-button>

                <uv-button v-if="row.item.xbrl && row.item.generated"
                    icon class="ml-1"
                    target="download"
                    color="success"
                    @click="downloadFile(getPackageLink(row))"
                    title="Download report"
                >
                    <v-icon>{{ icons.mdiArchiveArrowDown }}</v-icon>
                    <v-divider vertical></v-divider>
                    <v-menu offset-x left>
                        <template #activator="{on, attrs}">
                            <v-icon v-on="on" v-bind="attrs">{{ icons.mdiMenuDown }}</v-icon>
                        </template>
                        <v-sheet>
                            <uv-button icon
                                target="download"
                                color="info"
                                @click="downloadFile(getPackageLink(row, true))"
                                title="Download report with viewer"
                            >
                                <v-icon>{{ icons.mdiArchiveEye }}</v-icon>
                            </uv-button>
                        </v-sheet>
                    </v-menu>
                </uv-button>
                
                
                <uv-button v-if="!row.item.xbrl || row.item.generated"
                    icon
                    target="_blank"
                    color="success"
                    @click="openReportViewerFileInNewTab(row)"
                    :loading="row.item.actions.view_btn.busy"
                    title="Open report"
                >
                    <v-icon>{{ icons.mdiBookOpenVariant }}</v-icon>
                </uv-button>
                
                <template v-if="reportLogs[row.item.id] && reportLogs[row.item.id].length > 0">
                    <uv-button
                        icon
                        target="_blank"
                        color="info"
                        @click="handleOpenReportLogs(row)"
                        title="Show logs"
                    >
                        <v-icon>{{icons.mdiFileDocument}}</v-icon>
                    </uv-button>
                    <uv-button
                        icon
                        target="_blank"
                        color="error"
                        @click="handleDeleteReportLogs(row)"
                        title="Delete report logs"
                    >
                        <v-icon>{{icons.mdiFileDocumentRemove}}</v-icon>
                    </uv-button>
                </template>
                
                <uv-button
                    icon
                    target="_blank"
                    color="success"
                    :loading="row.item.actions.export_btn.busy"
                    @click="handleExportReport(row)"
                    title="Export report"
                >
                    <v-icon>mdi-file-export</v-icon>
                </uv-button>
                
                <uv-button v-if="row.item && row.item.id"
                    :disabled="row.item.actions.generate_btn.busy"
                    icon
                    color="error"
                    @click="deleteReport(row)"
                    :loading="row.item.actions.delete_btn.busy"
                    title="Delete report"
                >
                    <v-icon>{{ icons.mdiDeleteVariant }}</v-icon>
                </uv-button>
            </template>

            <template #item.generated="row">
                <v-icon v-if="row.item.generated" color="primary">{{icons.mdiCheckboxMarkedCircleOutline}}</v-icon>
            </template>

            <template #expanded-item="row">
                <td
                    :colspan="row.headers.length"
                    class="pa-0 ma-0"
                    v-if="row.item.actions.details_btn.pressed && row.item.files.length > 0"
                >
                    <v-container fluid class="pa-0 ma-0">
                        <v-card raised outline>
                            <v-list>
                                <v-list-item
                                    v-for="file in row.item.files"
                                    :key="'file_' + file.id"
                                >
                                    <v-row align="center">
                                        <span
                                            class="label text-capitalize text-dark text-left"
                                            >{{ file.type }}:</span
                                        >
                                        <span class="ml-2">
                                            <v-btn
                                                @click="downloadFile(getFileLink(file, row.item))"
                                                :download="file.file"
                                                text
                                                color="accent"
                                            >
                                                {{ file.file }}
                                            </v-btn>
                                        </span>

                                        <uv-button
                                            icon
                                            small
                                            color="error"
                                            title="Delete file"
                                            :loading="file.delete_btn.busy"
                                            :disabled="row.item.actions.generate_btn.busy"
                                            @click="deleteFile(row, file)"
                                        >
                                        <v-icon>mdi-delete-variant</v-icon>
                                        </uv-button>
                                    </v-row>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-container>
                </td>

                <td
                    :colspan="row.headers.length"
                    v-if="row.item.actions.generate_btn.pressed"
                    class="pa-0 ma-0"
                >
                    <v-container fluid class="pa-0 ma-0">
                        <v-card raised outline >
                            <v-toolbar dense flat>
                                <v-fade-transition>
                                    <span v-if="!row.item.actions.process_log.expanded && row.item.wsProc.steps.length" class="socketMsg">
                                        <span v-html="row.item.wsProc.steps.slice(-1)[0]?.socMsg" class="ml-2"></span>
                                    </span>
                                </v-fade-transition>

                                <v-spacer></v-spacer>

                                <uv-button icon small @click="row.item.actions.process_log.expanded = !row.item.actions.process_log.expanded">
                                    <v-icon :class="{rotated: row.item.actions.process_log.expanded}">$expand</v-icon>
                                </uv-button>
                                <uv-button icon small 
                                @click="toggleDetails(row, row.item.actions.generate_btn)"
                                :disabled="row.item.actions.generate_btn.busy"
                                >
                                    <v-icon small>mdi-close</v-icon>
                                </uv-button>
                            </v-toolbar>

                            <v-expand-transition>
                                <v-card v-if="row.item.actions.process_log.expanded" flat>
                                    <v-card-text>
                                        <template v-for="step in row.item.wsProc.steps" class="mb-1" elevation=1>
                                            <fa-icon
                                                :key="`icon-${step.step}-${row.item.id}`" 
                                                icon="spinner"
                                                size="2x"
                                                spin
                                                v-if="step.step == row.item.wsProc.steps.length - 1 && row.item.actions.generate_btn.busy">
                                            </fa-icon>
                                            <pre :key="`pre-${step.step}-${row.item.id}`" ><span v-html="step.stdout"></span></pre>
                                                <component :is="'style'" v-if="step.styles" :key="`styles-${step.step}-${row.item.id}`" >
                                                    {{ step.styles.join("\n")}}
                                                </component>
                                        </template>
                                    </v-card-text>
                                </v-card>
                            </v-expand-transition>
                        </v-card>
                    </v-container>
                    <v-card
                        id="status"
                        tile
                        outline
                        :color="
                            row.item.wsProc.status != 'error'
                                ? 'lime'
                                : 'red darken-4 white--text'
                        "
                        >Status: {{ row.item.wsProc.status }}</v-card
                    >
                </td>
            </template>
        </v-data-table>

        <v-dialog
            persistent
            dismissible
            v-model="uploadFilesForm.visible"
            width="80vw"
        >
            <v-card>
                <v-card-title class="primary white--text elevation-2">
                    Upload files for report:
                    <strong v-if="uploadFilesForm.table_row">{{
                        uploadFilesForm.table_row.item.name
                    }}</strong>
                </v-card-title>
                <v-divider light inset class="mb-2"></v-divider>
                <v-card-text>
                    <v-container>
                        <v-form
                            ref="uploadFilesForm"
                            enctype="multipart/form-data"
                            id="uploadFilesForm"
                        >
                            <template
                                v-for="i in Math.ceil(
                                    visibleFileInputs.length / 3
                                )"
                            >
                                <v-row :key="i">
                                    <v-col
                                        md="4"
                                        v-for="key in visibleFileInputs.slice((i - 1) * 3, (i - 1) * 3 + 3)"
                                        :key="key"
                                    >
                                        <v-file-input
                                            :name="key"
                                            chips
                                            show-size
                                            persistent-hint
                                            :disabled="disableFileInput(key)"
                                            :multiple="
                                                uploadFilesForm.data[key].multi
                                            "
                                            :accept="
                                                uploadFilesForm.data[key].accept
                                            "
                                            v-model="
                                                uploadFilesForm.data[key].value
                                            "
                                            :label="
                                                key[0].toUpperCase() +
                                                    key.slice(1)
                                            "
                                            :hint="
                                                uploadFilesForm.data[key]
                                                    .description
                                            "
                                            truncate-length="15"
                                        >
                                        </v-file-input>
                                    </v-col>
                                </v-row>
                            </template>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="secondary darken-1"
                        text
                        :disabled="uploadFilesForm.busy"
                        @click.prevent="closeUploadFilesForm"
                    >
                        Close
                    </v-btn>
                    <v-btn
                        color="success"
                        text
                        @click.prevent="uploadFiles"
                        :loading="uploadFilesForm.busy"
                    >
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="editReportForm.visible"
            persistent
            dismissible
            width="fit-content"
        >
            <validation-observer ref="form" v-slot="{ handleSubmit, reset }">
                <v-card>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="4">
                                    <uv-text-field
                                        name="name"
                                        label="Name"
                                        v-model="editReportForm.data.name"
                                        rules="required|min:3"
                                    ></uv-text-field>
                                </v-col>
                                <v-col>
                                    <uv-autocomplete 
                                    v-model="editReportForm.data.company_id" 
                                    :items="companyData.items" 
                                    item-text="name"
                                    item-value="id"
                                    label="Company"
                                    disabled>
                                    </uv-autocomplete>
                                </v-col>
                                <v-col>
                                    <uv-text-field
                                        name="url"
                                        label="URL"
                                        v-model="editReportForm.data.uri"
                                        rules="required|isurl"
                                    ></uv-text-field>
                                </v-col>
                                <v-col>
                                    <v-menu
                                        ref="datePicker"
                                        v-model="editReportForm.dateVisible"
                                        :close-on-content-click="false"
                                        :return-value.sync="editReportForm.data.date"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <uv-text-field
                                                v-model="editReportForm.data.date"
                                                label="Date"
                                                prepend-icon="mdi-calendar"
                                                v-bind="attrs"
                                                v-on="on"
                                                rules="required"
                                            ></uv-text-field>
                                        </template>
                                            <v-date-picker
                                                v-model="editReportForm.data.date"
                                                no-title
                                                scrollable
                                            >
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    text
                                                    color="secondary"
                                                    @click="
                                                        editReportForm.dateVisible = false
                                                    "
                                                >
                                                    Cancel
                                                </v-btn>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="
                                                        $refs.datePicker.save(
                                                            editReportForm.data.date
                                                        )
                                                    "
                                                >
                                                    OK
                                                </v-btn>
                                            </v-date-picker>
                                    </v-menu>
                                </v-col>
                               
                                <v-col cols="1" v-if="!isEditingXbrlReport">
                                    <uv-combobox
                                        v-model="editReportForm.data.xml_lang"
                                        label="Language"
                                        :items="languageItems"
                                        rules="required">
                                    </uv-combobox>
                                </v-col>
                                <v-col cols="1">
                                    <uv-switch-btn v-model="editReportForm.data.xbrl" label="Xbrl" @change="handleXbrlSwitch"></uv-switch-btn>
                                </v-col>
                            </v-row>
                            <template v-if="isEditingXbrlReport">
                                <v-row class="mb-1">
                                    <v-card elevation="0" class="col-12 outlinedCard">
                                        <div class="text-overline mb-2">Namespaces</div>
                                        <v-row dense v-for="(namespace, index) in editReportForm.data.namespaces" :key="index">
                                            <v-col cols="5">
                                                <uv-combobox
                                                    label="Name"
                                                    dense
                                                    v-model="namespace.name"
                                                    :items="Object.values(namespaceNameItems)"
                                                    item-text="name"
                                                    item-value="name"
                                                    :rules="`required|noundescore|uniqueNsName:${index}`"
                                                    @change="onNamespaceChange($event, index)">
                                                </uv-combobox>
                                            </v-col>
                                            <v-col cols="6">
                                                <uv-text-field v-if="!Array.isArray(namespaceNameItems[namespace.name]?.url)"
                                                    label="Url" 
                                                    dense
                                                    v-model="namespace.url"
                                                    rules="required">
                                                </uv-text-field>
                                                <uv-combobox v-else
                                                    :items="namespaceNameItems[namespace.name].url"
                                                    item-text="url"
                                                    item-value="url"
                                                    label="Url" 
                                                    dense
                                                    v-model="namespace.url"
                                                    rules="required">
                                                </uv-combobox>
                                                
                                            </v-col>
                                            <v-col cols="1" align="end">
                                                <uv-button @click="removeNamespace(index)" icon color="error" title="Remove namespace"><v-icon>mdi-delete</v-icon></uv-button>
                                            </v-col>
                                        </v-row>
                                        <v-row dense justify="end">
                                            <v-col cols="auto">
                                                <uv-button @click="addNamespaceField" color="success" icon title="Add namespace">
                                                    <v-icon>mdi-plus-thick</v-icon>
                                                </uv-button>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-row>
                                <v-row class="mb-1">
                                    <v-card elevation="0" class="col-12 outlinedCard">
                                        <div class="text-overline mb-2">Periods</div>
                                        <v-row v-for="(period, index) in editReportForm.data.periods" :key="index">
                                            <v-col cols="3">
                                                <uv-text-field dense label="Period name:" rules="required|min:3" v-model="editReportForm.data.periods[index].name"></uv-text-field>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-menu
                                                    ref="periodDatePickerStart"
                                                    v-model="editReportForm.data.periods[index].displayStartPicker"
                                                    :close-on-content-click="false"
                                                    :return-value.sync="editReportForm.data.periods[index].start_date"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="290px">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <uv-text-field 
                                                            v-model="editReportForm.data.periods[index].start_date"
                                                            dense 
                                                            label="Period Start"
                                                            prepend-icon="mdi-calendar" 
                                                            v-bind="attrs"
                                                            rules="required"
                                                            v-on="on"
                                                            >
                                                        </uv-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="editReportForm.data.periods[index].start_date"
                                                        no-title
                                                        scrollable
                                                        >
                                                        <v-spacer></v-spacer>
                                                <v-btn
                                                    text
                                                    color="secondary"
                                                    @click="
                                                        editReportForm.data.periods[index].displayStartPicker = false
                                                    "
                                                >
                                                    Cancel
                                                </v-btn>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="$refs.periodDatePickerStart[index].save(editReportForm.data.periods[index].start_date)"
                                                >
                                                    OK
                                                </v-btn>
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-menu
                                                    ref="periodDatePickerEnd"
                                                    v-model="editReportForm.data.periods[index].displayEndPicker"
                                                    :close-on-content-click="false"
                                                    :return-value.sync="editReportForm.data.periods[index].end_date"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="290px">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <uv-text-field 
                                                            v-model="editReportForm.data.periods[index].end_date"
                                                            dense 
                                                            label="Period End"
                                                            prepend-icon="mdi-calendar" 
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            rules="required"
                                                            >
                                                        </uv-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="editReportForm.data.periods[index].end_date"
                                                        no-title
                                                        scrollable
                                                        >
                                                        <v-spacer></v-spacer>
                                                <v-btn
                                                    text
                                                    color="secondary"
                                                    @click="
                                                        editReportForm.data.periods[index].displayEndPicker = false
                                                    "
                                                >
                                                    Cancel
                                                </v-btn>
                                                <v-btn
                                                    @click="$refs.periodDatePickerEnd[index].save(editReportForm.data.periods[index].end_date)"
                                                    text
                                                    color="primary"
                                                >
                                                    OK
                                                </v-btn>
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col cols="1" align="end">
                                                <uv-button @click="removePeriod(index)" icon color="error" title="Remove period"><v-icon>mdi-delete</v-icon></uv-button>
                                            </v-col>
                                        </v-row>
                                        
                                        <v-row dense justify="end">
                                            <v-col cols="auto">
                                                <uv-button @click="addPeriodField" color="success" icon title="Add period">
                                                    <v-icon>mdi-plus-thick</v-icon>
                                                </uv-button>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-row>
                                <v-row>
                                    <v-card elevation="0" class="col-12 outlinedCard">
                                        <div class="text-overline mb-2">Complex units</div>
                                        <template v-for="(unit, index) in editReportForm.data.complex_units">
                                            <v-row v-if="unit.deleted !== true" :key="index">
                                                <v-col cols="3">
                                                    <uv-text-field dense label="Unit name:" :value="unit.name" readonly></uv-text-field>
                                                </v-col>
                                                <v-col cols="4">
                                                    <uv-text-field dense label="Numerator:" :value="unit.numerator" readonly></uv-text-field>
                                                </v-col>
                                                <v-col cols="4">
                                                    <uv-text-field dense label="Denominator:" :value="unit.denominator" readonly></uv-text-field>
                                                </v-col>
                                                <v-col cols="1" align="end">
                                                    <uv-button @click="removeComplexUnit(index)" icon color="error" title="Remove complex unit"><v-icon>mdi-delete</v-icon></uv-button>
                                                    <uv-button @click="activeComplexUnit=index" icon color="warning" title="Edit complex unit"><v-icon>mdi-pencil</v-icon></uv-button>
                                                </v-col>
                                            </v-row>
                                        </template>
                                        
                                        <v-row dense justify="end">
                                            <v-col cols="auto">
                                                <uv-button @click="activeComplexUnit=-1" color="success" icon title="Add complex unit">
                                                    <v-icon>mdi-plus-thick</v-icon>
                                                </uv-button>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-row>
                                <v-row>
                                    <v-card elevation="0" class="col-12 outlinedCard" style="margin-top:0.5rem">
                                        <div class="text-overline mb-2">Default Values</div>
                                        <v-row>
                                            <v-col cols="2">
                                                <uv-combobox
                                                    name="period"
                                                    v-model="editReportForm.data.period"
                                                    label="Period"
                                                    :items="editReportForm.data.periods"
                                                    item-text="name"
                                                    dense
                                                    rules="required"
                                                    :return-object="false">
    
                                                </uv-combobox>
                                            </v-col>
                                            <v-col cols="2">
                                                <uv-combobox
                                                    v-model="editReportForm.data.period_type"
                                                    label="Period Type"
                                                    :items="periodTypeItems"
                                                    dense
                                                    rules="required">
                                                </uv-combobox>
                                            </v-col>
                                            <v-col cols="2"> 
                                                <uv-text-field 
                                                    label="Numeric Scale"
                                                    name="numericScale"
                                                    dense
                                                    type="number"
                                                    min="0"
                                                    rules="required"
                                                    v-model="editReportForm.data.numeric_scale">
                                                    </uv-text-field>
                                            </v-col>
                                            <v-col cols="2">
                                                <uv-autocomplete
                                                    label="Monetary Unit"
                                                    name="monetaryUnit"
                                                    :items="monetaryUnits"
                                                    item-value="id"
                                                    item-text="name"
                                                    dense
                                                    v-model="editReportForm.data.monetary_units"
                                                    rules="required"
                                                    :filter="findMonetaryUnit"
                                                    >
                                                        <template #item="{item}">
                                                            <v-list-item-content>
                                                                <v-list-item-title>{{item.id}}</v-list-item-title>
                                                                <v-list-item-subtitle>{{item.name}}</v-list-item-subtitle>
                                                            </v-list-item-content>
                                                            <v-list-item-action>
                                                                <uv-button color="info" icon :title="item.description" @click.stop="()=>false">
                                                                    <v-icon>mdi-information-variant</v-icon>
                                                                </uv-button>
                                                            </v-list-item-action>
                                                        </template>
                                                        <template #selection="{item}">
                                                            {{item.id}}
                                                        </template>
                                                </uv-autocomplete>
                                            </v-col>
                                            <v-col cols="4">
                                                <uv-combobox
                                                    v-model="editReportForm.data.numeric_transform"
                                                    label="Numeric Transform"
                                                    :items="numericTransformItems"
                                                    dense
                                                    rules="required">
                                                </uv-combobox>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="2">
                                                <uv-text-field 
                                                    label="Decimals"
                                                    name="decimals"
                                                    v-model="editReportForm.data.decimals"
                                                    dense
                                                    min="0"
                                                    type="number"
                                                    rules="required">
                                                </uv-text-field>
                                            </v-col>
                                            <v-col cols="4">
                                                <uv-text-field 
                                                    label="Entity Scheme"
                                                    name="entityScheme"
                                                    dense
                                                    v-model="editReportForm.data.entity_scheme"
                                                    rules="required">
                                                </uv-text-field>
                                            </v-col>
                                            <v-col cols="4">
                                                <uv-text-field 
                                                    label="Entity Identifier"
                                                    name="entityIdentifier"
                                                    v-model="editReportForm.data.entity_identifier"                                                  
                                                    dense
                                                    disabled
                                                    rules="required">
                                                </uv-text-field>
                                            </v-col>
                                            <v-col cols="2">
                                                <uv-combobox
                                                    v-model="editReportForm.data.xml_lang"
                                                    label="Language"
                                                    :items="languageItems"
                                                    dense
                                                    rules="required">
                                                </uv-combobox>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-row>
                                <v-row>
                                    <v-card elevation="0" class="col-12 outlinedCard" style="margin-top:0.5rem">
                                        <div class="text-overline mb-2">Taxonomy Info</div>
                                        <v-row>
                                            <v-col cols="4">
                                                <uv-text-field
                                                    name="tpName"
                                                    label="Taxonomy Name"
                                                    rules="required"
                                                    v-model="editReportForm.data.taxonomy_package_name">
                                                </uv-text-field>
                                            </v-col>
                                            <v-col cols="4">
                                                <uv-text-field
                                                    name="tpDescription"
                                                    label="Description"
                                                    rules="required"
                                                    v-model="editReportForm.data.taxonomy_package_description">
                                                </uv-text-field>
                                            </v-col>
                                            <v-col cols="4">
                                                <uv-text-field
                                                    name="tpVersion"
                                                    label="Version"
                                                    rules="required"
                                                    v-model="editReportForm.data.taxonomy_package_version">
                                                </uv-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="4">
                                                <uv-text-field
                                                    name="tpPublisherCountry"
                                                    label="Publisher Country"
                                                    rules="required"
                                                    v-model="editReportForm.data.taxonomy_package_publisher_country">
                                                </uv-text-field>
                                            </v-col>
                                            <v-col cols="4">
                                                <uv-text-field
                                                    name="tpPublisher"
                                                    label="Publisher"
                                                    rules="required"
                                                    v-model="editReportForm.data.taxonomy_package_publisher">
                                                </uv-text-field>
                                            </v-col>
                                            <v-col cols="4">
    
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-row>
                            </template>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="secondary" text @click="reset(); resetEditReportForm()"
                            >Cancel</v-btn
                        >
                        <v-btn
                            color="success"
                            :loading="editReportForm.busy"
                            text
                            @click="handleSubmit(onSubmit)"
                            >Save</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </validation-observer>
        </v-dialog>

        <uv-confirm-dialog ref="deleteConfirmation">
            Are you sure you want to delete the report?
            <br />
            Any uploaded or generated files will also be removed.
        </uv-confirm-dialog>

        <uv-confirm-dialog ref="fileDeleteConfirmation">
            Are you sure you want to delete the file?
        </uv-confirm-dialog>

        <uv-confirm-dialog ref="deleteReportLogs">
            Are you sure you want to delete the logs of this report?
        </uv-confirm-dialog>

        <uv-confirm-dialog ref="deleteAllReportsLogs">
            Are you sure you want to delete the logs for all the reports?
        </uv-confirm-dialog>

        <v-dialog :value="activeComplexUnit !== null" 
        persistent
        width="50%">
            <complex-unit-form :unit="activeComplexUnitItem" :report="editReportForm.data" @ok="updateComplexUnit" @cancel="activeComplexUnit = null"></complex-unit-form>
        </v-dialog>

        <v-dialog v-model="displayReportLogs" max-width="80vw">
            <v-card class="report-logs-container">
                <v-card-title class="d-flex justify-space-between align-end">
                    <span>Report logs</span>
                    <uv-button
                        icon
                        dark
                        small
                        title="Close Report Logs"
                        @click="displayReportLogs = false">
                        <v-icon color="black">mdi-close</v-icon>
                    </uv-button>
                </v-card-title>
                <v-card-text>
                    <v-expansion-panels>
                        <v-expansion-panel v-for="(item, i) in displayedReportLogs" :key="`report-log-${i}`">
                            <v-expansion-panel-header>
                                {{ i + 1 }}
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <span v-html="displayedReportLogs[i]" style="white-space: pre;"></span>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card-text>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import UvConfirmDialog from "@/components/ConfirmDialog";
import ComplexUnitForm from "@/views/Reports/ComplexUnitForm"
import axios from "axios";

import { downloadFileFromResponse, openFileInNewTab, openViewerFileInNewTab } from '../../plugins/utils';
import {mapMutations, mapState} from "vuex";

let defaults = {
    actions: {
        details_btn: {
            pressed: false
        },
        generate_btn: {
            busy: false,
            pressed: false
        },
        delete_btn: {
            busy: false
        },
        view_btn: {
            busy: false
        },
        process_log:{
            expanded: false
        },
        export_btn: {
            busy: false
        },
    },
    wsProc: {
        status: "",
        steps: [],
        connection: null,
    }
};

import {extend} from "vee-validate";
import {reactive} from "vue"
export default {
    name: "Reports",
    setup(){
        const icons = reactive({})
        import("@mdi/js").then(mdi=>{
            icons.mdiPlus = mdi.mdiPlus
            icons.mdiEye = mdi.mdiEye
            icons.mdiUpload = mdi.mdiUpload
            icons.mdiCogPlay = mdi.mdiCogPlay
            icons.mdiCogStop = mdi.mdiCogStop
            icons.mdiPencil = mdi.mdiPencil
            icons.mdiArchiveArrowDown = mdi.mdiArchiveArrowDown
            icons.mdiBookOpenVariant = mdi.mdiBookOpenVariant
            icons.mdiDeleteVariant = mdi.mdiDeleteVariant
            icons.mdiCheckboxMarkedCircleOutline = mdi.mdiCheckboxMarkedCircleOutline
            icons.mdiFileDocumentRemove = mdi.mdiFileDocumentRemove
            icons.mdiFileDocument = mdi.mdiFileDocument
            icons.mdiMenuDown = mdi.mdiMenuDown
            icons.mdiArchiveEye = mdi.mdiArchiveEye 
        })
        return {icons}
    },
    data: function() {
        return {
            companyData: {
                value: null,
                items: [],
                busy: false
            },
            tab: null,
            reports: {
                fields: [],
                items: [],
                loading: false,
                expanded: []
            },

            uploadFilesForm: {
                table_row: null,
                visible: false,
                alert: {},
                data: {
                    original: {
                        accept: "",
                        description: "Original report.",
                        value: null,
                    },
                    html: {
                        accept: "text/html,application/xhtml+xml",
                        description: "Report in HTML format.",
                        value: null
                    },
                    taxonomy: {
                        accept:
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel",
                        description:
                            "Excel file used to generate the taxonomy.",
                        value: null
                    },
                },
                busy: false
            },
            editReportForm: {
                table_row: null,
                alert: {},
                data: {},
                busy: false,
                visible: false,
                dateVisible: false,
                currentPeriodStart: false,
                curentPeriodEnd: false,
                previousPeriodStart: false,
                previousPeriodEnd: false,
                
            },
            entityIdentifiers: [],
            periodTypeItems: ['instant','instant-start','duration'],
            languageItems: ["en", "ro"],
            numericTransformItems: ['num-dot-decimal','num-comma-decimal','num-unit-decimal'],
            leiSearchTimer: null,
            leiSearchLoading: false,
            wsStatus: null,
            wsReconnect: true,
            activeComplexUnit: null,
            importButtonBusy: false,
            importedReport: null,
            reportLogs: null,
            displayReportLogs: false,
            displayedReportLogs: null,
            namespaceNameItems: {
                'ifrs-full': {
                    name: "ifrs-full", 
                    url:[
                        "https://xbrl.ifrs.org/taxonomy/2022-03-24/ifrs-full",
                        "https://xbrl.ifrs.org/taxonomy/2021-03-24/ifrs-full"
                    ]
                },
                utr: {
                   name: "utr",
                   url: "http://www.xbrl.org/2009/utr" 
                },
                'dtr-types': {
                    name: "dtr-types",
                    url: "http://www.xbrl.org/dtr/type/2020-01-21"
                }
            }
        };
    },
    methods: {
        ...mapMutations({
            setCurrentCompany: 'SET_currentCompany',
        }),
        processReports: function(reportsData) {
            this.reports.fields = [
                {
                    value: "id",
                    text: "Id"
                },
                {
                    value: "name",
                    text: "Name"
                },
                {
                    value: "company",
                    text: "Company"
                },
                {
                    value: "uri",
                    text: "URI"
                },
                {
                    value: "date",
                    text: "Date"
                },
                {
                    value: "generated",
                    text: "Generated",
                    align: "center"
                },
                {
                    value: "actions",
                    text: "Actions",
                    align: "right",
                    sortable: false
                }
            ];
            this.reports.items = [];
            for (let i = 0; i < reportsData.length; i++) {
                let item = reportsData[i];
                if(item.deleted == true) {
                    continue
                }
                item.actions = JSON.parse(JSON.stringify(defaults.actions)); //vrem copie, nu referinta la acelasi obiect
                if (item.files){
                    item.files = item.files.map((file)=>{
                        file.delete_btn = {busy:false};
                        return file;
                    });
                }

                item.wsProc = JSON.parse(JSON.stringify(defaults.wsProc));
                this.reports.items.push(item);
            }
        },
        getFileLink: function(file, report) {
            let link = [];
            if (file.type !== "static"){
                link.push("files");
                link.push(report.id);
                link.push(file.type);
                link.push(file.file);
            }else{
                link.push("files");
                link.push(report.id);
                link.push("build");
                link.push(report.name);
                link.push("reports");
                link.push(file.file);
            }
            return "/" + link.join("/");
        },
        getPackageLink: function(row, viewer=false) {
            let link = [];
            link.push("files");
            link.push(row.item.id);
            link.push("build");
            if (!viewer){
                link.push(row.item.name + ".zip");
            }else{
                link.push(row.item.name + "_viewer.zip");
            }
            return "/" + link.join("/");
        },
        getReportLink: function(row) {
            let link = [];
            link.push("files");
            link.push(row.item.id);
            link.push("build");
            link.push(row.item.name);
            link.push("reports");
            link.push(`${row.item.name}-viewer.html`);
            return "/" + link.join("/");
        },
        getStaticLink: function(row) {
            let link = [];
            link.push("files");
            link.push(row.item.id);
            link.push("build");
            link.push(row.item.name);
            link.push("reports");
            link.push(`${row.item.name}.xhtml`);
            return "/" + link.join("/");
        },
        getReportJsViewerLink: function(row) {
            let link = [];
            link.push("files");
            link.push(row.item.id);
            link.push("build");
            link.push(row.item.name);
            link.push("reports");
            link.push("js");
            link.push("ixbrlviewer.js");
            return "/" + link.join("/");
        },
        showUploadFilesForm: function(row) {
            this.uploadFilesForm.table_row = row;
            let accept = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
            if (!row.item.xbrl){
                accept += ",application/pdf"
            }
            this.uploadFilesForm.data.original.accept = accept;
            this.uploadFilesForm.visible = true;
        },
        closeUploadFilesForm: function() {
            //do not clear this.uploadFilesForm.table_row
            this.uploadFilesForm.visible = false;
            this.uploadFilesForm.busy = false;
            for (const key in this.uploadFilesForm.data){
                this.uploadFilesForm.data[key].value = null;
            }
        },
        downloadFile: function(path) {
            this.$apiHttp({
                url: path,
                responseType: "blob",
            }).then(response => {
                downloadFileFromResponse(response)
            })
        },
        handleExportReport: function(row) {
            row.item.actions.export_btn.busy = true;
            this.$apiHttp({
                url: `/report/export/${row.item.id}`,
                responseType: "blob",
            }).then(response => {
                downloadFileFromResponse(response)
            }).finally(() => {
                row.item.actions.export_btn.busy = false;
            })
        },
        importReport() {
            if (this.importedReport) {
                this.importButtonBusy = true;
                let formData = new FormData();
                formData.append("archive", this.importedReport)
                this.$apiHttp({
                    method: "put",
                    url: "report/import",
                    data: formData,
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                }).then((response) => {
                    let new_row = {
                        actions: JSON.parse(
                            JSON.stringify(defaults.actions)
                        ),
                        wsProc: JSON.parse(
                            JSON.stringify(defaults.wsProc)
                        ),
                        ...response.data.data
                    };

                    if (new_row.files) {
                        new_row.files = new_row.files.map((file) => {
                            file.delete_btn = { busy: false };
                            return file;
                        });
                    }
                    //this.reports.items.push(new_row);
                    this.reports.items.splice(this.reports.items.length, 0, new_row);
                }).finally(() => {
                    this.importedReport = null;
                    this.importButtonBusy = false;
                })
            }
        },
        openFileInNewTab: function(path) {
            this.$apiHttp({
                url: path,
                responseType: "blob",
            }).then(response => {
                openFileInNewTab(response)
            })
        },
        openReportViewerFileInNewTab: function(row) {
            if (row.item.xbrl){
                let pathHtml = this.getReportLink(row);
                let pathJs = this.getReportJsViewerLink(row);
                row.item.actions.view_btn.busy = true;
    
                Promise.all([
                    this.$apiHttp({
                        url: pathHtml,
                        responseType: "blob",
                    }),
                    this.$apiHttp({
                        url: pathJs,
                        responseType: "blob",
                    }),
                ]).then(values => {
                    let htmlBlob = new Blob([values[0].data], {
                        type: values[0].headers["content-type"],
                    });
                    let jsBlob = new Blob([values[1].data], {
                        type: values[1].headers["content-type"],
                    });
                    openViewerFileInNewTab(htmlBlob, jsBlob)
                }).finally(() => {
                    row.item.actions.view_btn.busy = false;
                });
            }else{
                let staticPath = this.getStaticLink(row);
                row.item.actions.view_btn.busy = true;
                this.$apiHttp({
                    url: staticPath,
                    responseType: "blob",
                }).then((response) => {
                    openFileInNewTab(response);
                }).finally(() => {
                    row.item.actions.view_btn.busy = false;
                });
            }
        },
        uploadFiles: function(ev) {
            ev.preventDefault();

            this.uploadFilesForm.busy = true;
            this.uploadFilesForm.alert = {};
            let url = [
                "report",
                "files",
                this.uploadFilesForm.table_row.item.id
            ];

            let formData = new FormData();
            for (const key in this.uploadFilesForm.data) {
                if (this.uploadFilesForm.data[key].value){
                    formData.append(key, this.uploadFilesForm.data[key].value);
                }
            }

            url = "/" + url.join("/");
            let vm = this;
            this.$apiHttp({
                method: "post",
                url: url,
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
                .then(function(response) {
                    //eslint-disable-next-line
                    let item = vm.uploadFilesForm.table_row.item;
                    let report = response.data.data;
                    report.files = report.files.map((f)=>{
                        f.delete_btn = {busy:false}
                        return f;
                    });
                    report.actions = JSON.parse(JSON.stringify(defaults.actions));
                    let index = vm.reports.items.indexOf(item);
                    vm.reports.items.splice(index, 1, report);
                    vm.closeUploadFilesForm();
                })
                .finally(function() {
                    vm.uploadFilesForm.busy = false;
                });
        },
        toggleEditReportForm: function(row) {
            this.editReportForm.data = {};
            if (row) {
                //edit
                this.editReportForm.table_row = row;

                
                this.editReportForm.data = JSON.parse(JSON.stringify(row.item));
                if (row.item.xbrl) this.renewEntityIdentifierData(row.item.entity_identifier);
            }else{
                this.resetData()
            }
            this.editReportForm.visible = true;
        },
        resetEditReportForm: function() {
            this.editReportForm.alert = {};
            this.editReportForm.data = {};
            this.editReportForm.table_row = null;
            this.editReportForm.busy = false;
            this.editReportForm.visible = false;
        },
        onSubmit: function() {
            if (this.editReportForm.data.xbrl) {
                let defaultPeriodValid = false;
                defaultPeriodValid = this.editReportForm.data.periods.find(period => {
                    if(period.name === this.editReportForm.data.period) {
                        return true;
                    } else {
                        return false;
                    }
                });

                if(!defaultPeriodValid) {
                    this.$refs.form.setErrors({
                            period: ['This period does not exist']
                    });
                    return;
                }
            } 
            this.editReportForm.busy = true;

            let method = this.editReportForm.data.id ? "put" : "post";
            let vm = this;
            vm.$apiHttp({
                method: method,
                url: "/report",
                data: this.editReportForm.data
            })
                .then(function (response) {
                    if (vm.editReportForm.table_row) {
                        let editedItem = vm.reports.items.find(itm => {
                            if (itm.id === response.data.data.id) return itm;
                        })
                        let index = vm.reports.items.indexOf(editedItem);
                        if (editedItem?.company_id !== response.data.data.company_id){
                            vm.reports.items.splice(index, 1);
                        }else{
                            let row = {
                                actions: JSON.parse(
                                    JSON.stringify(defaults.actions)
                                ),
                                ...response.data.data
                            };
                            row.files = row.files.map((f) => {
                                f.delete_btn = { busy: false }
                                return f;
                            });
                            vm.reports.items.splice(index, 1, row);
                        }
                    } else {
                        let new_row = {
                            actions: JSON.parse(
                                JSON.stringify(defaults.actions)
                            ), //vrem copie nu referinta la acelasi obiect
                            ...response.data.data
                        };
                        vm.reports.items.push(new_row);
                    }
                    vm.resetEditReportForm();
                })
                .finally(function () {
                    vm.editReportForm.busy = false;
                });                     
        },
        deleteReport: async function(row) {
            let delete_all = await this.$refs.deleteConfirmation.open();
            if (delete_all == true) {
                row.item.actions.delete_btn.busy = true;

                let vm = this;
                this.$apiHttp({
                    method: "delete",
                    url: "/report/" + row.item.id
                })
                    .then(function() {
                        vm.reports.items = vm.reports.items.filter(function(
                            item
                        ) {
                            return item.id != row.item.id;
                        });
                    })
                    .finally(function() {
                        row.item.actions.delete_btn.busy = false;
                    });
            }
        },
        deleteFile: async function(row, file) {
            let confirmDelete = await this.$refs.fileDeleteConfirmation.open();
            if (confirmDelete) {
                file.delete_btn.busy = true
                this.$apiHttp({
                    method: "delete",
                    url: "/report/file/" + row.item.id + `?file_id=${file.id}`
                })
                .then(() => {
                    row.item.files = row.item.files.filter((f)=>{
                        return file.id != f.id;
                    });
                })
                .catch(function() {
                    file.delete_btn.busy = false;
                });
            }
        },
        toggleDetails: function(row, action_btn) {
            const pressed = action_btn.pressed;
            row.item.actions.generate_btn.pressed = false;
            row.item.actions.details_btn.pressed = false;

            action_btn.pressed = !pressed;
            let expanded = this.reports.expanded.filter((i) => i.id != row.item.id)
            if (action_btn.pressed) {
                expanded.push(row.item);
            }

            this.reports.expanded = expanded;
        },
        connectWsProc: function(report_id) {
            let protocol = "ws";

            if (window.location.protocol.includes("https")) {
                protocol = "wss";
            }
            let report = this.reports.items.find((r) => {
                return r.id == report_id;
            });

            report.wsProc =JSON.parse(JSON.stringify(defaults.wsProc));

            let wss = new WebSocket(
                `${protocol}://${window.location.host}/api/ws/generate_report/${report_id}?token=${this.$store.state.apiToken.access_token}`
            );
            report.wsProc.connection = wss;

            let newReportLogs = this.reportLogs[report_id];
            if (newReportLogs){
                if (newReportLogs.length > 9){
                    newReportLogs.shift();
                }else{
                    newReportLogs.push("");
                }
            }else{
                newReportLogs = [];
                newReportLogs.push("");
                this.reportLogs[report_id] = newReportLogs;
            }

            let vm = this;
            wss.onopen = function() {
                let msg = {
                    action: "generate",
                    report_id: report_id
                };

                wss.send(JSON.stringify(msg));
            };

            wss.onerror = function() {
                wss.close();
            };

            wss.onclose = function() {
                if (report.wsProc.connection) {
                    report.wsProc.connection = null;
                }
            };

            wss.onmessage = function(ev) {
                let msg = JSON.parse(ev.data);

                if (msg.alert) {
                    if (typeof msg.alert == "string"){
                        msg.alert = msg.alert.replace(/'/g, '"');
                        msg.alert = JSON.parse(msg.alert);
                    }
                    vm.$store.commit("setGlobalMessage", msg.alert);
                }

                if (msg.action == "generate" && msg.report_id == report.id) {
                    let step = report.wsProc.steps.find((s) => s.step == msg.step);
                    let currentReportLogs = vm.reportLogs[msg.report_id];
                    if (!step){
                        report.wsProc.steps.push({
                            step: msg.step,
                            stdout: msg.stdout,
                            socMsg: msg.stdout,
                            styles: msg.styles || []
                        })
                    }else{
                        if (report.wsProc.steps.length === 3 || report.wsProc.steps.length === 6){
                            step.stdout += msg.stdout;
                            step.socMsg = msg.stdout;
                            if (msg.styles?.length > 0){
                                for (let style of msg.styles) {
                                    if (step.styles.indexOf(style) == -1) {
                                        step.styles.push(style);
                                    }
                                }
                            }
                        }
                        currentReportLogs[currentReportLogs.length-1] += msg.stdout;
                    }
                }
                vm.$vuetify.goTo("#status");
            };
        },
        connectWsStatus: function(socket_id = 1, show_connect = false) {
            let protocol = "ws";

            if (window.location.protocol.includes("https")) {
                protocol = "wss";
            }

            let wss = new WebSocket(
                `${protocol}://${window.location.host}/api/ws/reports_status?token=${this.$store.state.apiToken.access_token}`
            );
            
            wss.id = socket_id;
            
            let vm = this;
            wss.onopen = function() {
                console.log(`Status wss ${wss.id} connected`);
                vm.wsStatus = wss;
                if (show_connect) {
                    vm.$store.commit("setGlobalMessage", {
                        severity: "success",
                        message: "Connected to server"
                    });
                }
            };

            wss.onerror = function() {
                console.log(`Status wss ${wss.id} error`);
            };

            wss.onclose = function(ev) {
                console.log(`Status wss ${wss.id} close with code ${ev.code}`);
                
                for (let i = 0; i < vm.reports.items.length; i++) {
                    vm.reports.items[i].actions.generate_btn.busy = false;
                }
                if (!ev.wasClean && vm.wsReconnect) {
                    vm.$store.commit("setGlobalMessage", {
                        type: "error",
                        message: `Server disconnected. Reconnecting...`
                    });
                    if (vm.wsStatus){
                        vm.wsStatus = null;
                    }

                    setTimeout(function() {
                        console.log(`Status wss ${wss.id} delete`);
                        vm.connectWsStatus(wss.id + 1, true);
                    }, 5000);
                }
            };

            wss.onmessage = function(ev) {
                let msg = JSON.parse(ev.data);

                if (msg.alert) {
                    if (typeof msg.alert == "string"){
                        msg.alert = msg.alert.replace(/'/g, '"');
                        msg.alert = JSON.parse(msg.alert);
                    }
                    vm.$store.commit("setGlobalMessage", msg.alert);
                }
                
                let report = vm.reports.items.find(report => report.id == msg.report_id)
                if (!report){
                    return;
                }
                if (msg.generated != null){
                    report.generated = msg.generated
                    localStorage.setItem('reportLogs', JSON.stringify(vm.reportLogs));
                }

                report.actions.generate_btn.busy = msg.status == "processing"
                report.wsProc.status = msg.status
            }
        },
        closeWs(){
            for (let report of this.reports.items){
                if (report.wsProc.connection){
                    report.wsProc.connection.close(1000);
                    report.wsProc.connection = null;
                }
            }

            this.wsReconnect = false;
            if (this.wsStatus){
                this.wsStatus.close(1000);
                this.wsStatus = null
            }

            window.removeEventListener("beforeunload", this.closeWs);
        },
        handleOpenReportLogs: function(row){
            this.displayReportLogs = true;
            this.displayedReportLogs = this.reportLogs[row.item.id];
        },
        deleteAllReportLogs: async function(){
            let confirmDeleteAllLogs = await this.$refs.deleteAllReportsLogs.open();
            if(confirmDeleteAllLogs) {
                this.reportLogs = {};
                localStorage.removeItem("reportLogs");
            }            
        },
        handleDeleteReportLogs: async function(row){
            let confirmDeleteReportLogs = await this.$refs.deleteReportLogs.open();
            if (confirmDeleteReportLogs) {
                let reportLogs = Object.assign(this.reportLogs[row.item.id]);
                if (reportLogs && reportLogs.length > 0){
                    //this.reportLogs[row.item.id] = [];
                    this.$set(this.reportLogs, row.item.id, [])
                }
                localStorage.setItem('reportLogs', JSON.stringify(this.reportLogs));
            }
        },
        generateReport: function(row) {
            row.item.actions.generate_btn.pressed = false;
            this.toggleDetails(row, row.item.actions.generate_btn);
            if (!row.item.actions.generate_btn.pressed) {
                return;
            }
            
            row.item.actions.details_btn.pressed = false;

            //incepe procesul de generare raport
            //row.item.actions.generate_btn.busy = true;
            this.connectWsProc(row.item.id);            
        },
        stopReport(row){
            let msg = {
                action: "stop",
                report_id: row.item.id
            }

            row.item.wsProc?.connection?.send(JSON.stringify(msg));
        },
        disableFileInput: function(key){
            if (key != "html" && key != "original"){
                return false;
            }

            if (key == "html"){
                return this.uploadFilesForm.data["original"].value != null
            }

            if (key == "original"){
                return this.uploadFilesForm.data["html"].value != null
            }
        },
        hasTemplate: function(row){
            if (row.item.files?.length > 0){
                const files = row.item.files.filter((f)=>{
                    return f.type == "template";
                });

                return files.length > 0;
            }

            return false;
        },
        async getEntityIdentifier(filter) {
            try {
                const result = await axios.get(`https://api.gleif.org/api/v1/lei-records?&filter[entity.names]=${filter}`);
                let searchResults = result.data.data.map(item => ({ text: item.attributes.entity.legalName.name, value: item.attributes.lei}))
                let difference = searchResults.filter(x => !this.entityIdentifiers.includes(x))
                this.entityIdentifiers.push(...difference)
                this.leiSearchLoading = false;
            } catch(error) {
                this.$store.commit("setGlobalMessage", {
                    severity: "error",
                    message: error
                });
          }
        },
        debounceEntityIdentifierSearch(data) {
            if(data && data.length > 1) {
                let that = this;
                clearTimeout(this.leiSearchTimer);
                this.leiSearchLoading = true;
                this.leiSearchTimer = setTimeout( function () {
                    that.getEntityIdentifier(data);
                }, 2000)
            }
        },
        addNamespaceField() {
            this.editReportForm.data.namespaces.push({ name: '', url: ''});
        },
        addPeriodField() {
            this.editReportForm.data.periods.push({ name: '', start: '', end: ''});
        },
        removeComplexUnit(index){
            this.$set(this.editReportForm.data.complex_units[index], "deleted", true)
        },
        updateComplexUnit(unit){
            if (this.activeComplexUnit == -1){
                this.editReportForm.data.complex_units.push(unit);
            }else{
                this.$set(this.editReportForm.data.complex_units, this.activeComplexUnit, unit);
            }

            this.activeComplexUnit = null;
        },
        removeNamespace(index) {
            this.editReportForm.data.namespaces.splice(index, 1);
        },
        removePeriod(index) {
            this.editReportForm.data.periods.splice(index, 1);
        },
        resetData() {
            let initialData = {
                name: null,
                company: this.selectedCompany.name,
                uri: this.selectedCompany.uri,
                date: null,
                entity_identifier: this.selectedCompany.entity_identifier,
                period: null,
                period_type: null,
                monetary_units: null,
                complex_units: [],
                numeric_scale: null,
                numeric_transform: null,
                decimals: null,
                entity_scheme: this.selectedCompany.entity_scheme ?? "http://standards.iso.org/iso/17442",
                language: null,
                periods: [],
                namespaces: [],
                taxonomy_package_description: null,
                taxonomy_package_name: null,
                taxonomy_package_publisher:null,
                taxonomy_package_publisher_country: null,
                taxonomy_package_version: null,
                xbrl: true,
                company_id: this.currentCompany
            };
            this.editReportForm.data = initialData;
        },
        async renewEntityIdentifierData(id) {
            try {

                const result = await axios.get(`https://api.gleif.org/api/v1/lei-records/${id}`);
                this.entityIdentifiers.push({ text: result.data.data.attributes.entity.legalName.name, value: result.data.data.attributes.lei});

            } catch(err) {
                this.$store.commit("setGlobalMessage", {
                    severity: "error",
                    message: err
                });
            }
        },
        findMonetaryUnit(item, queryText) {
            const searchText = queryText.toLowerCase();
            return item.name.toLowerCase().indexOf(searchText) > -1 || item.id.toLowerCase().indexOf(searchText) > -1
        },
        onNamespaceChange(value, nsIdx){
            const namespace = this.editReportForm.data.namespaces[nsIdx]
            if (value instanceof Object){
                namespace.name = value?.name
                if (Array.isArray(value?.url)){
                    namespace.url = value.url[0]
                }else{
                    namespace.url = value?.url
                }
            }else{
                namespace.name = value
            }
        },
        uniqueNsName(value, [nsIdx]){
            nsIdx = parseInt(nsIdx)
            const _filter = function(ns, idx){
                return idx != nsIdx && ns.name === value
            }
            if (this.editReportForm.data.namespaces && this.editReportForm.data.namespaces.length > 0){
                const sameValue = Object.values(this.editReportForm.data.namespaces).filter(_filter);
                return sameValue.length == 0
            }
        },
        handleXbrlSwitch(value){
            let { name, company, uri, date, xbrl, company_id } = this.editReportForm.data;
            if (!value) {
                this.editReportForm.data = { name, company, uri, date, xbrl, company_id }
            } else {
                this.resetData();
                this.editReportForm.data.name = name;
                this.editReportForm.data.company = company;
                this.editReportForm.data.uri = uri;
                this.editReportForm.data.date = date;
                this.editReportForm.data.xbrl = xbrl;
                this.editReportForm.data.company_id = company_id;
            }
        },
        loadCompanies() {
            this.companyData.busy = true;
            if (this.isAdmin) {
                this.$apiHttp({
                    url: "/companies"
                })
                    .then((response) => {
                        this.companyData.items = response.data;
                        if (this.companyData.items?.length == 1){
                            this.companyData.value = this.companyData.items[0].id;
                        }
                    }).finally(() => {
                        this.companyData.busy = false;
                    });
            }else{
                this.$apiHttp({
                    url: "/companies/assigned"
                })
                    .then((response) => {
                        this.companyData.items = [response.data];
                        this.companyData.value = response.data.id
                    }).finally(() => {
                        this.companyData.busy = false;
                    });
            }
        }
    },
    computed:{
        ...mapState("xbrlData" ,['monetaryUnits']),
        ...mapState(['userRole', 'currentCompany']),
        wsStatusConnected(){
            return this.wsStatus?.readyState == WebSocket.OPEN;
        },
        activeComplexUnitItem(){
            if (this.activeComplexUnit === null || this.activeComplexUnit == -1){
                return null;
            }
            
            return this.editReportForm.data?.complex_units[this.activeComplexUnit]
        },
        isEditingXbrlReport() {
            return this.editReportForm.data?.xbrl;
        },
        visibleFileInputs() {
            let files = Object.keys(this.uploadFilesForm.data);
            if (!this.uploadFilesForm.table_row?.item.xbrl) {
                files = files.filter((file) => ["html", "original"].includes(file));
            }
            return files;
        },
        isAdmin(){
            return this.userRole === 1;
        },
        selectedCompany() {
            let foundCompany;
            if (this.companyData.value !== null && this.companyData.value !== undefined){
                foundCompany = this.companyData.items.find((company) => company.id === this.companyData.value);
            }
            return foundCompany;
        }
    },
    created: function() {
        this.$store.commit("setPageMenu", []);
        this.companyData.value = this.currentCompany;
        extend('uniqueNsName', {
            validate: this.uniqueNsName,
            message: "Must be unique"
        },{
            immediate: true
        })
        
        this.loadCompanies();
        window.addEventListener("beforeunload", this.closeWs);

    },
    watch: {
        "companyData.value": function(newVal) {
            let vm = this;
            this.setCurrentCompany(newVal);
            if (newVal !== null && newVal !== undefined){
                vm.reports.loading = true;
                vm.$apiHttp({
                    url: "/report",
                    method: "get",
                    params: {
                        company_id: newVal
                    }
                }).then(function(response) {
                    vm.processReports(response.data);
                    vm.closeWs();
                    vm.connectWsStatus();
                    vm.reports.loading = false;
                });
                let reportLogsString = localStorage.getItem("reportLogs");
                if (reportLogsString){
                    this.reportLogs = JSON.parse(reportLogsString);
                }else{
                    this.reportLogs = {};
                }
            }
        }
    },
    destroyed: function() {
        this.closeWs();
    },
    components: {
        "uv-confirm-dialog": UvConfirmDialog,
        "complex-unit-form": ComplexUnitForm
    },
};
</script>
<style scoped lang="scss">
:deep() {
    pre {
        white-space: pre-wrap;
    }

    .rotated{
        transform: rotate(180deg);
    }

    .import-report .v-icon{
        color: var(--v-success-base)
    }
}

.outlinedCard {
    border: 1px solid black;
}

:deep() {
    .v-dialog:not(#deleteConfirmation):not(.v-dialog--fullscreen) {
        top: 10px !important;
        position: absolute !important;
    }
}
.socketMsg {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 90vw;
}
.generateRaportDetails {
    height: 400px;
    overflow: auto;
}
:deep() {
    .report-logs-container{
        .v-expansion-panel-content__wrap{
            overflow-x: auto;
        }
    }
}
</style>